import { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cx from 'classnames';
import Big from 'big.js';

import config from 'config';
import { useGlobal } from 'context/Global';
import useBreakpoint from 'hooks/useBreakpoint';
import { parseNumber, formatNumber, formatDate } from 'lib/formatters';
import { serializeToText } from 'lib/serializeTiptap';
import { number, optionalRichText, makeNullable } from 'lib/validators';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';
import PopMenu from 'components/common/PopMenu';
import Tiles from 'components/common/Tiles';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormNote from 'components/form/FormNote';
import FormNumberInput from 'components/form/FormNumberInput';
import FormTextEditor from 'components/form/FormTextEditor';
import FundraiserAdminEditUpdateMedia from './FundraiserAdminEditUpdateMedia';

export const FUNDRAISING_UPDATE_EDIT_FIELDS = gql`
  fragment FundraisingUpdateEditFields on FundraisingUpdate {
    id
    createdAt
    embeddedMedia
    fundraiserId
    message
    performanceUnits
    shouldSendToDonors
    type

    fundraiser {
      id
      awardedAchievements(achievementCode: "performanceGoal") {
        fundraiserAchievementId
        achievementCode
        achievedAt
        seenAt
        name
        value
        image
      }
    }
  }
`;

const CREATE_FUNDRAISING_UPDATE = gql`
  ${FUNDRAISING_UPDATE_EDIT_FIELDS}
  mutation CreateFundraisingUpdate($data: FundraisingUpdateInput!) {
    createFundraisingUpdate(FundraisingUpdate: $data) {
      ...FundraisingUpdateEditFields
    }
  }
`;

const UPDATE_FUNDRAISING_UPDATE = gql`
  ${FUNDRAISING_UPDATE_EDIT_FIELDS}
  mutation UpdateFundraisingUpdate($data: FundraisingUpdateInput!) {
    updateFundraisingUpdate(FundraisingUpdate: $data) {
      ...FundraisingUpdateEditFields
    }
  }
`;

const DELETE_FUNDRAISING_UPDATE = gql`
  mutation DeleteFundraisingUpdate($id: String!) {
    deleteFundraisingUpdate(id: $id)
  }
`;

const GET_FUNDRAISER = gql`
  ${FUNDRAISING_UPDATE_EDIT_FIELDS}

  query GetFundraisingUpdateFundraiser($id: String!) {
    findFundraisers(id: $id) {
      id
      avatarImage
      campaign {
        id
        activeFeatures {
          activityTracking
        }
        fundraiserDefaultHeaderAvatarImage
        performanceMode
        performanceStatus
        performanceStartDate
        timezone
      }
      performanceEstimate
      performanceSettings {
        id
        metricLabelPlural
      }
      stats {
        id
        aggregatedPerformanceResult
      }
      status
      updates(where: { type: "performance" }, limit: 1, order: "reverse:createdAt") {
        ...FundraisingUpdateEditFields
      }

      awardedAchievements(achievementCode: "performanceGoal") {
        fundraiserAchievementId
        achievementCode
        achievedAt
        seenAt
        name
        value
        image
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  performanceUnits: number.when(['$includePerformance'], ([includePerformance], schema) => {
    // Either cannot have performance, or should be omitted due to fundraiser status
    if (!includePerformance) return schema.strip();

    return makeNullable(
      schema
        .test('negative', 'Value must be greater than 0', (val) => !val || val > 0)
        .test('decimal2', 'Enter up to two decimal digits', (val) =>
          (val?.toString() ?? '').match(/(^\d*|\.\d\d?)$/)
        )
        .transform((value) => (value === 0 ? null : value))
    );
  }),

  message: optionalRichText,

  embeddedMedia: makeNullable(
    yup.object().shape({
      service: makeNullable(yup.string()),
      url: makeNullable(yup.string()),
    })
  ),

  shouldSendToDonors: yup.boolean(),
});

const FundraiserAdminEditUpdateForm = ({ initialValues, onComplete, onDelete }) => {
  const { addToast } = useGlobal();
  const mobile = !useBreakpoint('lg');
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const [createFundraisingUpdate] = useMutation(CREATE_FUNDRAISING_UPDATE, {
    refetchQueries: [{ query: GET_FUNDRAISER, variables: { id: initialValues.fundraiserId } }],
    awaitRefetchQueries: true,
  });
  const [updateFundraisingUpdate] = useMutation(UPDATE_FUNDRAISING_UPDATE, {
    refetchQueries: [{ query: GET_FUNDRAISER, variables: { id: initialValues.fundraiserId } }],
    awaitRefetchQueries: true,
  });
  const [deleteFundraisingUpdate] = useMutation(DELETE_FUNDRAISING_UPDATE, {
    refetchQueries: [
      { query: GET_FUNDRAISER, variables: { id: initialValues.fundraiserId } },
      'GetCommunityFundraisingUpdates',
    ],
    awaitRefetchQueries: true,
  });

  const { data } = useQuery(GET_FUNDRAISER, { variables: { id: initialValues.fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers?.[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);

  const isCreating = !initialValues.id;
  const canHavePerformance = campaign?.activeFeatures.activityTracking;
  const isBeforePerformanceStart = campaign?.performanceStatus === 'notStarted';

  const { control, watch, handleSubmit, reset, setValue, register, unregister, formState } =
    useForm({
      defaultValues: {
        shouldSendToDonors: initialValues.shouldSendToDonors ?? true,
        performanceUnits: initialValues.performanceUnits
          ? formatNumber(initialValues.performanceUnits, '0.[00]')
          : '',
        message: initialValues.message ?? undefined,
        embeddedMedia: {
          service: initialValues.embeddedMedia?.service ?? null,
          url: initialValues.embeddedMedia?.url ?? '',
        },
      },
      context: {
        includePerformance:
          canHavePerformance && ['active', 'confirmed'].includes(fundraiser.status),
      },
      resolver: yupResolver(validationSchema),
    });
  const { errors } = formState;

  const performanceUnits = parseNumber(watch('performanceUnits'));
  const shouldSendToDonors = watch('shouldSendToDonors');

  const metricLabel = useMemo(() => {
    if (!fundraiser) return null;
    if (!canHavePerformance) return null;
    const sinceDate = fundraiser.updates[0]
      ? fundraiser.updates[0].createdAt
      : campaign.performanceStartDate;

    return (
      <>
        Enter{' '}
        <strong className="font-medium">{fundraiser.performanceSettings.metricLabelPlural}</strong>
        {campaign.performanceMode === 'interval' &&
          !isBeforePerformanceStart &&
          ` since ${formatDate(sinceDate)}`}
      </>
    );
  }, [canHavePerformance, isBeforePerformanceStart, campaign, fundraiser]);

  const canDelete = useMemo(() => {
    if (isCreating) return false;
    if (initialValues?.type === 'fundraising') return true;
    return ['active', 'confirmed'].includes(fundraiser?.status);
  }, [isCreating, initialValues, fundraiser?.status]);

  const deliveryState = useMemo(
    () => ({
      locked: !isCreating,
      label: shouldSendToDonors
        ? `${!isCreating ? 'Posted and sent' : 'Post and send'} to ${
            !isCreating ? 'your' : 'my'
          } donors`
        : `${!isCreating ? 'Posted' : 'Post'} only on ${!isCreating ? 'your' : 'my'} page`,
    }),
    [isCreating, shouldSendToDonors]
  );

  const submitLabel = useMemo(() => {
    const action = !isCreating || deliveryState.label.includes('hidden') ? 'Save' : 'Post';
    return `${action} update`;
  }, [isCreating, deliveryState.label]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const hasPerformanceUnits = values.performanceUnits != null;

        // Need to ensure that the update contains SOMETHING
        if (
          !hasPerformanceUnits &&
          !serializeToText(values.message) &&
          !values.embeddedMedia?.url
        ) {
          addToast({
            id: 'edit-update',
            type: 'error',
            duration: 'long',
            message: 'At least one field needs to be filled out before saving',
          });
          return;
        }

        const saveData = {
          ...values,
          type: hasPerformanceUnits ? 'performance' : 'fundraising',
          shouldSendToDonors: values.shouldSendToDonors,
          fundraiserId: initialValues.fundraiserId,
        };

        // Backend expects performanceUnits to be a string
        if (hasPerformanceUnits) {
          saveData.performanceUnits = values.performanceUnits.toString();
        } else {
          delete saveData.performanceUnits;
        }

        if (!isCreating) saveData.id = initialValues.id;

        const mutateFn = isCreating ? createFundraisingUpdate : updateFundraisingUpdate;
        const result = await mutateFn({ variables: { data: saveData } });

        if (!mobile) {
          addToast({
            id: 'edit-update',
            type: 'success',
            duration: 'short',
            message: `Your update has been ${
              isCreating && saveData.performanceUnits !== 0 ? 'posted' : 'saved'
            }`,
          });
        }

        onComplete(result.data[isCreating ? 'createFundraisingUpdate' : 'updateFundraisingUpdate']);

        reset();
      } catch (err) {
        if (config('/debug')) console.error(err);

        addToast({
          id: 'edit-update',
          type: 'error',
          duration: 'long',
          message: 'There was an issue with saving your update. Please try again later.',
        });
      }
    },
    [
      createFundraisingUpdate,
      updateFundraisingUpdate,
      onComplete,
      isCreating,
      initialValues,
      addToast,
      reset,
      mobile,
    ]
  );

  const handleDelete = useCallback(async () => {
    try {
      await deleteFundraisingUpdate({ variables: { id: initialValues.id } });

      addToast({
        id: 'remove-update',
        type: 'success',
        duration: 'short',
        message: 'Your update has been removed.',
      });

      onDelete();
    } catch (err) {
      if (config('/debug')) console.error(err);

      addToast({
        id: 'edit-update',
        type: 'error',
        duration: 'long',
        message: 'There was an issue removing your update. Please try again later.',
      });
    }
  }, [initialValues.id, deleteFundraisingUpdate, onDelete, addToast]);

  // We update the value of this field manually without a form control
  useEffect(() => {
    register('shouldSendToDonors');
    return () => unregister('shouldSendToDonors');
  }, [register, unregister]);

  if (confirmingDelete) {
    return (
      <ConfirmDialog
        show
        title="Delete update?"
        confirmLabel="Delete"
        declineLabel="Cancel"
        onConfirm={handleDelete}
        onDecline={() => setConfirmingDelete(false)}
        confirmColor="red"
      >
        <p>This update will be permanently removed. Are you sure?</p>
      </ConfirmDialog>
    );
  }

  return (
    <form className="px-6 py-8 md:p-8" onSubmit={handleSubmit(onSubmit)}>
      <Tiles spacing="sm" className="items-center">
        <Avatar
          image={
            fundraiser?.avatarImage ??
            campaign?.fundraiserDefaultHeaderAvatarImage ??
            config('/defaultFundraiserAvatar')
          }
          size="lg"
        />
        <PopMenu
          title="Select audience"
          disabled={deliveryState.locked}
          trigger={
            <Button
              as="span"
              color="gray-300"
              className="text-sm font-medium flex items-center"
              padding="sm"
              disabled={deliveryState.locked}
            >
              {deliveryState.label}
              {!deliveryState.locked && <FontAwesomeIcon icon={faCaretDown} className="ml-4" />}
            </Button>
          }
          closeOnClick
        >
          <div className="px-4 py-3 md:max-w-2xs">
            <h2 className="font-medium mb-2">Send donors this update?</h2>
            <Tiles columns={1} spacing="2xs" className="group">
              <button
                type="button"
                onClick={() => setValue('shouldSendToDonors', true, { shouldDirty: true })}
                className={cx(
                  'block w-full py-2 px-3 font-medium flex items-center text-left rounded',
                  {
                    'bg-gray-300': shouldSendToDonors,
                    'hover:text-gray-600 transition-colors duration-200': !shouldSendToDonors,
                  }
                )}
              >
                <div
                  className={cx(
                    'border border-2 rounded-full h-5 w-5 mr-3 flex items-center justify-center',
                    {
                      'border-teal-500 bg-teal-500': shouldSendToDonors,
                      'border-gray-400': !shouldSendToDonors,
                    }
                  )}
                >
                  <FontAwesomeIcon icon={faCheck} size="sm" className="text-white" fixedWidth />
                </div>
                <p className="text-xs">Post and send to my donors</p>
              </button>
              <button
                type="button"
                onClick={() => setValue('shouldSendToDonors', false, { shouldDirty: true })}
                className={cx(
                  'block w-full py-2 px-3 font-medium flex items-center text-left rounded',
                  {
                    'bg-gray-300': !shouldSendToDonors,
                    'hover:text-gray-600 transition-colors duration-200': shouldSendToDonors,
                  }
                )}
              >
                <div
                  className={cx(
                    'border border-2 rounded-full h-5 w-5 mr-3 flex items-center justify-center',
                    {
                      'border-gray-400': shouldSendToDonors,
                      'border-teal-500 bg-teal-500': !shouldSendToDonors,
                    }
                  )}
                >
                  <FontAwesomeIcon icon={faCheck} size="sm" className="text-white" fixedWidth />
                </div>
                <p className="text-xs">Post only on my page</p>
              </button>
            </Tiles>
          </div>
        </PopMenu>
      </Tiles>

      <div className="mt-8">
        <FormFieldGroup>
          {canHavePerformance && (
            <FormNode>
              <FormLabel htmlFor="performanceUnits">{metricLabel}</FormLabel>
              <Controller
                control={control}
                name="performanceUnits"
                render={({ field, fieldState }) => (
                  <FormNumberInput
                    decimalsLimit={2}
                    disabled={
                      isBeforePerformanceStart ||
                      !['active', 'confirmed'].includes(fundraiser.status)
                    }
                    suffix={
                      <p className="text-sm font-medium">
                        {formatNumber(
                          Big(fundraiser.stats.aggregatedPerformanceResult)
                            .minus(initialValues.performanceUnits ?? 0)
                            .plus(performanceUnits ?? 0),
                          '0,0.[00]'
                        )}{' '}
                        / {formatNumber(fundraiser.performanceEstimate, '0,0')}
                        <FontAwesomeIcon icon={faBullseyeArrow} size="lg" className="ml-3" />
                      </p>
                    }
                    allowDecimals
                    status={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                )}
              />
              {isBeforePerformanceStart && (
                <FormNote>
                  You can log your {fundraiser.performanceSettings.metricLabelPlural} starting{' '}
                  {formatDate(campaign.performanceStartDate)}
                </FormNote>
              )}
              {!isBeforePerformanceStart && (
                <FormNote>
                  If you do not wish to record {fundraiser.performanceSettings.metricLabelPlural},
                  leave blank
                </FormNote>
              )}
              <ErrorMessage
                errors={errors}
                name="performanceUnits"
                render={({ message }) => <FormNote status="error">{message}</FormNote>}
              />
            </FormNode>
          )}
          <FormNode>
            {canHavePerformance && <FormLabel htmlFor="message">Share a quick update</FormLabel>}
            <Controller
              control={control}
              name="message"
              render={({ field, fieldState }) => (
                <FormTextEditor
                  placeholder="I'm excited to share..."
                  preset="inline"
                  status={fieldState.error ? 'error' : 'default'}
                  autoFocus={!mobile && !initialValues.embeddedMedia?.service}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="message"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
          <Controller
            control={control}
            name="embeddedMedia"
            render={({ field }) => <FundraiserAdminEditUpdateMedia {...field} />}
          />
        </FormFieldGroup>
      </div>

      <div className="mt-10">
        <Tiles columns={canDelete ? 2 : 1} spacing="xs">
          {canDelete && (
            <Button
              as="button"
              type="button"
              color="gray-800"
              className="w-full font-medium"
              onClick={() => setConfirmingDelete(true)}
              outline
            >
              Delete
            </Button>
          )}
          <Button as="button" type="submit" color="primary" className="w-full font-medium">
            {submitLabel}
          </Button>
        </Tiles>
      </div>
    </form>
  );
};

FundraiserAdminEditUpdateForm.propTypes = {
  initialValues: PropTypes.shape({
    fundraiserId: PropTypes.string.isRequired,
    id: PropTypes.string,
    embeddedMedia: PropTypes.shape({
      url: PropTypes.string,
      service: PropTypes.string,
    }),
    message: PropTypes.arrayOf(PropTypes.shape({})),
    performanceUnits: PropTypes.string,
    shouldSendToDonors: PropTypes.bool,
    type: PropTypes.oneOf(['fundraising', 'performance']),
  }).isRequired,
  onComplete: PropTypes.func,
  onDelete: PropTypes.func,
};

FundraiserAdminEditUpdateForm.defaultProps = {
  onComplete: () => {},
  onDelete: () => {},
};

export default FundraiserAdminEditUpdateForm;
