import { useState, useEffect, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faEllipsisH,
  faTimes,
  faLink,
  faTrash,
  faArrowRight,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faYoutube,
  faStrava,
  faXTwitter,
  faInstagram,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';

import useIframely from 'hooks/useIframely';
import AppBar from 'components/common/AppBar';
import AppBarIcon from 'components/common/AppBarIcon';
import AppBarToolbar from 'components/common/AppBarToolbar';
import AppBarTitle from 'components/common/AppBarTitle';
import Button from 'components/common/Button';
import IframelyCard from 'components/common/IframelyCard';
import Modal from 'components/common/Modal';
import Tiles from 'components/common/Tiles';
import FormInput from 'components/form/FormInput';
import FormImageUploader from 'components/form/FormImageUploader';
import MapMyRunLogo from '../../svg/logo-mapmyrun.svg';

const services = {
  cloudinary: {
    label: 'Upload Photo',
    icon: <FontAwesomeIcon icon={faImage} className="text-2xl text-green-500" fixedWidth />,
  },
  youtube: {
    label: 'YouTube Video',
    icon: <FontAwesomeIcon icon={faYoutube} className="text-2xl text-brand-youtube" fixedWidth />,
  },
  strava: {
    label: 'Strava Activity',
    icon: <FontAwesomeIcon icon={faStrava} className="text-2xl text-brand-strava" fixedWidth />,
  },
  mapmyrun: {
    label: 'Map My Run',
    icon: (
      <span className="inline-block text-center" style={{ width: '26.66px' }}>
        <MapMyRunLogo className="w-5 mx-auto fill-current text-brand-mapmyrun" />
      </span>
    ),
  },
  twitter: {
    label: 'X Post',
    icon: <FontAwesomeIcon icon={faXTwitter} className="text-2xl text-brand-twitter" fixedWidth />,
  },
  instagram: {
    label: 'Instagram Post',
    icon: (
      <FontAwesomeIcon icon={faInstagram} className="text-2xl text-brand-instagram" fixedWidth />
    ),
  },
  facebook: {
    label: 'Facebook Post',
    icon: <FontAwesomeIcon icon={faFacebook} className="text-2xl text-brand-facebook" fixedWidth />,
  },
  generic: {
    label: 'Embed Link',
    icon: <FontAwesomeIcon icon={faLink} size="lg" className="text-gray-500" fixedWidth />,
  },
};

// eslint-disable-next-line no-unused-vars
const FundraiserAdminEditUpdateMedia = forwardRef(({ value, onChange }, ref) => {
  const { service, url } = value;

  const [isSelecting, setIsSelecting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(!!url);

  const { data } = useIframely(isPreviewing ? url : null);

  const resetMedia = useCallback(() => {
    onChange({ service: null, url: '' });
    setIsPreviewing(false);
  }, [onChange, setIsPreviewing]);

  // Update the service when we generate the iframely preview
  useEffect(() => {
    if (!url || !isPreviewing) return;
    // eslint-disable-next-line camelcase
    const iframelyService = data?.provider_name?.toLowerCase();
    if (iframelyService) {
      const serviceName = services[iframelyService] ? iframelyService : 'generic';
      if (serviceName !== service) onChange({ service: serviceName, url });
    }
  }, [data, isPreviewing, url, service, onChange]);

  return (
    <>
      <Modal show={isSelecting} onHide={() => setIsSelecting(false)} className="lg:max-w-md">
        <AppBar className="z-10">
          <AppBarToolbar>
            <Tiles spacing="sm">
              <button type="button" onClick={() => setIsSelecting(false)}>
                <AppBarIcon icon={faTimes} />
              </button>
            </Tiles>
            <AppBarTitle title="Add to your update" className="md:font-medium" />
            <Tiles />
          </AppBarToolbar>
        </AppBar>
        <div className="px-4 py-6 md:p-6">
          <Tiles columns={[1, 2]} spacing={['xs', 'sm']}>
            {Object.entries(services).map(([name, { icon, label }]) => (
              <button
                key={name}
                type="button"
                className="flex sm:font-medium flex-row-reverse justify-between sm:flex-row sm:justify-start items-center w-full hover:bg-gray-300 transition-colors duration-200 rounded-md py-3 px-3"
                onClick={() => {
                  onChange({ service: name, url });
                  setIsSelecting(false);
                }}
              >
                <span className="inline-block sm:mr-3">{icon}</span>
                {label}
              </button>
            ))}
          </Tiles>
        </div>
      </Modal>

      {!service ? (
        <div className="border bg-white border-gray-400 rounded-md flex p-2 pl-4 items-center">
          <button
            type="button"
            onClick={() => setIsSelecting(true)}
            className="font-medium grow text-left"
          >
            Add media
          </button>
          <Tiles spacing="xs" className="items-center">
            <button
              type="button"
              className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-gray-300 transition-colors duration-200"
              onClick={() => onChange({ service: 'cloudinary', url })}
            >
              {services.cloudinary.icon}
            </button>
            <button
              type="button"
              className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-gray-300 transition-colors duration-200"
              onClick={() => onChange({ service: 'youtube', url })}
            >
              {services.youtube.icon}
            </button>
            <button
              type="button"
              className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-gray-300 transition-colors duration-200"
              onClick={() => onChange({ service: 'strava', url })}
            >
              {services.strava.icon}
            </button>
            <button
              type="button"
              className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-gray-300 transition-colors duration-200"
              onClick={() => setIsSelecting(true)}
            >
              <FontAwesomeIcon icon={faEllipsisH} className="text-2xl" fixedWidth />
            </button>
          </Tiles>
        </div>
      ) : (
        (() => {
          switch (service) {
            case 'cloudinary': {
              return (
                <FormImageUploader
                  value={url}
                  preview={() =>
                    url ? (
                      <Image
                        publicId={url}
                        className="block max-w-full mx-auto rounded-lg"
                        alt=""
                      />
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400" spin />
                    )
                  }
                  onChange={(newValue) => {
                    if (!newValue) {
                      resetMedia();
                      return;
                    }

                    onChange({ service, url: newValue });
                  }}
                  onAbort={() => {
                    if (!url) resetMedia();
                  }}
                  openOnMount={!url}
                  showEditIndicator={!!url}
                  editLabel
                  allowCrop
                />
              );
            }

            default: {
              return (
                <Tiles columns={1} spacing="sm">
                  <div className="flex items-center">
                    <div className="grow">
                      <FormInput
                        value={url}
                        onChange={(newValue) => onChange({ service, url: newValue })}
                        prefix={services[service].icon}
                        placeholder={`${services[service].label} URL`}
                        disabled={isPreviewing}
                        autoFocus
                      />
                    </div>
                    <div className="shrink-0 pl-2">
                      {isPreviewing || !url ? (
                        <Button
                          as="button"
                          type="button"
                          color="gray-300"
                          onClick={resetMedia}
                          padding="sm-even"
                        >
                          <FontAwesomeIcon icon={faTrash} size="lg" />
                        </Button>
                      ) : (
                        <Button
                          as="button"
                          type="button"
                          color="secondary"
                          onClick={() => setIsPreviewing(true)}
                          padding="sm-even"
                        >
                          <FontAwesomeIcon icon={faArrowRight} size="lg" />
                        </Button>
                      )}
                    </div>
                  </div>
                  {isPreviewing && <IframelyCard url={url} displayError />}
                </Tiles>
              );
            }
          }
        })()
      )}
    </>
  );
});

FundraiserAdminEditUpdateMedia.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    service: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

FundraiserAdminEditUpdateMedia.defaultProps = {};

export default FundraiserAdminEditUpdateMedia;
