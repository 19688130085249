import Popover from './Popover';

export const reactionConfig = {
  like: '👍',
  love: '❤',
  clap: '👏',
  cry: '🥲',
  yay: '🥳',
  haha: '😆',
  wow: '😮',
};

type UserReactionTriggerProps = PropsWithNonEmptyChildren<{
  onReact: (reaction: keyof typeof reactionConfig) => void;
}>;

const UserReactionTrigger = ({ onReact, children }: UserReactionTriggerProps) => (
  <Popover
    className="leading-none"
    trigger={children}
    positions={['top', 'bottom', 'right', 'left']}
    align="center"
    closeOnClick
  >
    <div className="bg-white rounded-full shadow-lg flex gap-x-3 py-1.5 px-3">
      {Object.entries(reactionConfig).map(([reaction, emoji]) => (
        <button
          key={reaction}
          type="button"
          className="text-3xl transition-all duration-200 hover:scale-125"
          onClick={() => onReact(reaction as keyof typeof reactionConfig)}
        >
          <span role="img" aria-label={reaction}>
            {emoji}
          </span>
        </button>
      ))}
    </div>
  </Popover>
);

export default UserReactionTrigger;
