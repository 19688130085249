import { useState, useCallback } from 'react';
import cx from 'classnames';
import { Image } from 'cloudinary-react';

import { gql, useQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import Big from 'big.js';

import config from 'config';
import { useGlobal } from 'context/Global';
import { formatTimeAgo, formatNumber, formatCurrency } from 'lib/formatters';
import { serializeToJSX } from 'lib/serializeTiptap';
import Avatar from 'components/common/Avatar';
import Card from 'components/common/Card';
import ConfirmDialog from 'components/common/ConfirmDialog';
import IframelyCard from 'components/common/IframelyCard';
import Link from 'components/common/Link';
import FundraisingUpdateUserReaction from 'components/common/FundraisingUpdateUserReaction';
import FundraiserAdminEditUpdate from 'components/fundraiser-admin/FundraiserAdminEditUpdate';
import { reactionConfig } from './UserReactionTrigger';

export const FUNDRAISING_UPDATE_FIELDS = gql`
  fragment FundraisingUpdateFields on FundraisingUpdate {
    id
    createdAt
    type
    isFeatured
    performanceUnits
    message
    likeCounter
    loveCounter
    clapCounter
    cryCounter
    yayCounter
    hahaCounter
    wowCounter
    myReaction
    embeddedMedia
    stravaActivity
  }
`;

export const FUNDRAISING_UPDATE_CAMPAIGN_FIELDS = gql`
  fragment FundraisingUpdateCampaignFields on Campaign {
    id
    fundraiserDefaultHeaderAvatarImage
  }
`;

export const FUNDRAISING_UPDATE_FUNDRAISER_FIELDS = gql`
  fragment FundraisingUpdateFundraiserFields on Fundraiser {
    id
    resolvedName
    avatarImage
    performanceSettings {
      id
      metricLabel
      metricLabelPlural
    }
    stats {
      id
      pledgeValue
    }
  }
`;

const GET_CAMPAIGN_SETTINGS = gql`
  query CampaignFundraisingUpdateSettings($id: String!) {
    findCampaigns(id: $id) {
      id
      widgets {
        id
        type
      }
    }
  }
`;

const CREATE_CAMPAIGN_WIDGET = gql`
  mutation UpdateCampaignWidgets($data: CampaignWidgetInput!) {
    createCampaignWidget(CampaignWidget: $data) {
      id
      type
    }
  }
`;

const UPDATE_FUNDRAISING_UPDATE = gql`
  mutation UpdateFundraisingUpdate($data: FundraisingUpdateInput!) {
    updateFundraisingUpdate(FundraisingUpdate: $data) {
      id
      isFeatured
    }
  }
`;

const EMBED_WIDTHS = {
  sm: '360',
  md: '430',
  lg: '500',
  xl: '560',
};

export interface Reactions {
  likeCounter?: number;
  loveCounter?: number;
  clapCounter?: number;
  cryCounter?: number;
  yayCounter?: number;
  hahaCounter?: number;
  wowCounter?: number;
  myReaction?: keyof typeof reactionConfig;
}

interface Update extends Reactions {
  id: string;
  type: string;
  createdAt: string;
  isFeatured?: boolean;
  performanceUnits?: string;
  message?: {}[];
  embeddedMedia?: { service: string; url: string };
  stravaActivity?: {
    id: string;
    name: string;
    description?: string;
    athlete?: { id: string };
    map?: { polyline: string };
    embed_token?: string;
    visibility?: string;
  };
}

type FundraisingUpdateProps = {
  update: Update;

  fundraiser: {
    id: string;
    resolvedName: string;
    avatarImage?: string;
    performanceSettings?: {
      metricLabel: string;
      metricLabelPlural: string;
    };
    stats: {
      pledgeValue: string;
    };
  };

  campaign: {
    id: string;
    fundraiserDefaultHeaderAvatarImage?: string;
  };

  rounded?: boolean;
  className?: string;
  canEdit?: boolean;
  canFeature?: boolean;
  stravaEmbedWidth?: keyof typeof EMBED_WIDTHS;
  size?: 'sm' | 'lg';
};

const FundraisingUpdate = ({
  update,
  fundraiser,
  campaign,
  rounded = true,
  canFeature = false,
  canEdit = false,
  className = '',
  stravaEmbedWidth = 'sm',
  size = 'sm',
}: FundraisingUpdateProps) => {
  const { addToast } = useGlobal();
  const [isEditing, setIsEditing] = useState(false);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const embedWidth = EMBED_WIDTHS[stravaEmbedWidth];

  const [updateFundraisingUpdate] = useMutation(UPDATE_FUNDRAISING_UPDATE);
  const [createCampaignWidget] = useMutation(CREATE_CAMPAIGN_WIDGET);
  const { refetch: fetchCampaignSettings } = useQuery(GET_CAMPAIGN_SETTINGS, {
    variables: { id: campaign.id },
    fetchPolicy: 'cache-first',
    skip: true,
  });

  const handleFeatureClick = useCallback(async () => {
    try {
      const isAdding = !update.isFeatured;

      if (isAdding) {
        const result = await fetchCampaignSettings();
        const hasSection = result.data.findCampaigns[0].widgets.some(
          (x) => x.type === 'communityActivity'
        );

        if (!hasSection) {
          setShowSectionModal(true);
          return;
        }
      }

      await updateFundraisingUpdate({
        variables: {
          data: {
            id: update.id,
            isFeatured: isAdding,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateFundraisingUpdate: {
            id: update.id,
            __typename: 'FundraisingUpdate',
            isFeatured: isAdding,
          },
        },
        update: (cache) =>
          cache.evict({
            id: `Campaign:${campaign.id}`,
            fieldName: 'updates',
            args: { where: { isFeatured: true }, order: 'reverse:createdAt' },
          }),
      });

      addToast({
        id: 'feature-update',
        type: isAdding ? 'success' : 'info',
        duration: 'short',
        message: `Update has been ${isAdding ? 'added to' : 'removed from'} homepage`,
      });

      setShowSectionModal(false);
    } catch (err) {
      if (config('/debug')) console.error(err);

      addToast({
        id: 'feature-update',
        type: 'error',
        duration: 'long',
        message: 'There was an issue with your request. Please try again later.',
      });
    }
  }, [
    update.isFeatured,
    update.id,
    campaign.id,
    fetchCampaignSettings,
    setShowSectionModal,
    updateFundraisingUpdate,
    addToast,
  ]);

  const handleConfirmSection = useCallback(async () => {
    try {
      const result = await fetchCampaignSettings();

      await createCampaignWidget({
        variables: {
          data: {
            campaignId: result.data.findCampaigns[0].id,
            scope: 'campaign',
            type: 'communityActivity',
            title: 'Community Activity',
          },
        },
      });

      handleFeatureClick();
    } catch (err) {
      if (config('/debug')) console.error(err);

      addToast({
        id: 'feature-update-section',
        type: 'error',
        duration: 'long',
        message: 'There was an issue with your request. Please try again later.',
      });
    }
  }, [fetchCampaignSettings, createCampaignWidget, handleFeatureClick, addToast]);

  const imageSize = {
    sm: '500',
    lg: '1000',
  }[size];

  return (
    <>
      {showSectionModal && (
        <ConfirmDialog
          show
          title="Activate Community Activity?"
          confirmLabel="Let's do it!"
          declineLabel="Maybe later"
          onConfirm={handleConfirmSection}
          onDecline={() => setShowSectionModal(false)}
        >
          <p>
            Would you like to turn on the &ldquo;Featured Updates&rdquo; widget? This displays
            favorite fundraiser updates on the homepage of your campaign and makes them more visible
            in the fundraiser community feed.
          </p>
        </ConfirmDialog>
      )}

      {isEditing && (
        <FundraiserAdminEditUpdate
          initialValues={{ id: update.id, fundraiserId: fundraiser.id }}
          onHide={() => setIsEditing(false)}
        />
      )}

      <Card
        padding={!rounded ? '2xs' : size === 'sm' ? 'xs' : 'sm'}
        depth="none"
        border
        radius={rounded ? 'md' : 'none'}
        className={className}
      >
        <header className="flex items-center gap-x-6">
          <div className="flex items-center gap-x-4 grow overflow-hidden">
            <Link href={`/f/${fundraiser.id}`}>
              <Avatar
                size="md"
                image={
                  fundraiser.avatarImage ??
                  campaign.fundraiserDefaultHeaderAvatarImage ??
                  config('/defaultFundraiserAvatar')
                }
                className="shrink-0"
              />
            </Link>
            <div className="overflow-hidden">
              <Link href={`/f/${fundraiser.id}`}>
                <p className={cx('font-medium truncate', { 'text-lg': size === 'lg' })}>
                  {fundraiser.resolvedName}
                </p>
              </Link>
              <p className={cx('text-gray-600', { 'text-sm': size === 'sm' })}>
                {formatTimeAgo(update.createdAt, 'narrow')}
              </p>
            </div>
          </div>

          <div
            className={cx('shrink-0 flex items-center', {
              'gap-x-3': size === 'lg',
              'gap-x-5': size === 'sm',
            })}
          >
            <div className="shrink-0">
              <FundraisingUpdateUserReaction display={`button-${size}`} {...update} />
            </div>
            {canEdit && (
              <button
                type="button"
                className={cx('flex items-center leading-none transition-colors duration-200', {
                  'h-12 px-4 rounded-full border border-gray-300 hover:border-gray-400':
                    size === 'lg',
                })}
                onClick={() => setIsEditing(true)}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  className={cx({
                    'text-lg': size === 'sm',
                    'text-base': size !== 'sm',
                  })}
                />
                {size === 'lg' && <span className="inline-block ml-2 font-medium">Edit</span>}
              </button>
            )}
            {canFeature && (
              <button
                type="button"
                className={cx(
                  'leading-none transition-colors duration-200 flex items-center text-gray-700',
                  {
                    'h-12 px-4 rounded-full border hover:bg-gray-200': size === 'lg',
                    'border-gray-300 hover:border-gray-400': size === 'lg' && !update.isFeatured,
                    'border-gray-400': size === 'lg' && update.isFeatured,
                    'text-theme-secondary': update.isFeatured,
                  }
                )}
                onClick={handleFeatureClick}
              >
                <FontAwesomeIcon
                  icon={update.isFeatured ? faStarSolid : faStar}
                  className={cx({
                    'text-lg': size === 'sm',
                    'text-base': size !== 'sm',
                  })}
                />
                {size === 'lg' && (
                  <span className="inline-block ml-2 font-medium">
                    {update.isFeatured ? 'Featured' : 'Feature'}
                  </span>
                )}
              </button>
            )}
          </div>
        </header>

        {update.type === 'performance' && (
          <div className="mt-6 py-2 flex justify-center items-center">
            <div className="text-center grow px-4">
              <p
                className={cx('font-medium', {
                  'text-lg': size === 'sm',
                  'text-2xl': size === 'lg',
                })}
              >
                {formatNumber(update.performanceUnits, '0,0.[00]')}
              </p>
              <p className={cx('capitalize text-gray-600', { 'text-sm': size === 'sm' })}>
                {fundraiser.performanceSettings.metricLabelPlural}
              </p>
            </div>
            <div className="text-center grow px-4">
              <p
                className={cx('font-medium', {
                  'text-lg': size === 'sm',
                  'text-2xl': size === 'lg',
                })}
              >
                {formatCurrency(fundraiser.stats.pledgeValue)}
              </p>
              <p className={cx('capitalize text-gray-600', { 'text-sm': size === 'sm' })}>
                Per {fundraiser.performanceSettings.metricLabel}
              </p>
            </div>
            <div className="text-center grow px-4">
              <p
                className={cx('font-medium', {
                  'text-lg': size === 'sm',
                  'text-2xl': size === 'lg',
                })}
              >
                {formatCurrency(
                  update.performanceUnits
                    ? Big(update.performanceUnits).times(Big(fundraiser.stats.pledgeValue))
                    : 0
                )}
              </p>
              <p className={cx('capitalize text-gray-600', { 'text-sm': size === 'sm' })}>Earned</p>
            </div>
          </div>
        )}

        {update.message && (
          <div className="mt-6 break-words rich-text">{serializeToJSX(update.message)}</div>
        )}

        {update.embeddedMedia?.url && (
          <div className="mt-6">
            {update.embeddedMedia.service === 'cloudinary' ? (
              <Image
                publicId={update.embeddedMedia.url}
                className="block rounded-lg max-w-full mx-auto"
                alt=""
                dpr="auto"
                width={imageSize}
                crop="scale"
              />
            ) : (
              <IframelyCard url={update.embeddedMedia.url} />
            )}
          </div>
        )}

        {update.stravaActivity?.visibility === 'everyone' && (
          <div className="flex justify-center items-center mt-5">
            <iframe
              id="stravaEmbed"
              title={update.stravaActivity.id}
              height={update.stravaActivity.map?.polyline ? '380' : '180'}
              width={embedWidth}
              frameBorder="0"
              scrolling="yes"
              src={`https://www.strava.com/activities/${update.stravaActivity.id}/embed/${update.stravaActivity.embed_token}`}
            />
          </div>
        )}
        <div className="mt-6">
          <FundraisingUpdateUserReaction display="counts" {...update} />
        </div>
      </Card>
    </>
  );
};

export default FundraisingUpdate;
